import type { WritableDraft } from 'immer';

import { FieldValidationLevel, type FieldValidationRule, type NumericField } from '@talos/kyoko';
import type { ManualRFQPricingState } from './types';

export const validateSpread: FieldValidationRule<NumericField, WritableDraft<ManualRFQPricingState>> = (
  field,
  context
) => {
  const bidPriceField = context?.form.bidPriceField;
  const offerPriceField = context?.form.offerPriceField;

  if (bidPriceField?.hasValue && offerPriceField?.hasValue) {
    const bidPrice = bidPriceField.bigValue;
    const offerPrice = offerPriceField.bigValue;

    // Prioritize showing other errors
    if (bidPrice == null || offerPrice == null) {
      return null;
    }

    if (bidPrice.gt(offerPrice)) {
      return {
        message: 'Crossing spread',
        level: FieldValidationLevel.Error,
      };
    }
  }

  return null;
};
