import { DateTimeDurationPickerValueType, DEFAULT_SLIPPAGES, type Slippage } from '@talos/kyoko';
import type { OMSView } from 'components/OMS/OMSView';
import type { OrderStrategiesEnum } from '../tokens/orderStrategy';

export type TradingSettingsContextProps = typeof DefaultTradingSettingsConfig & typeof DefaultTradingSettingsMethods;

export const DefaultTradingSettingsConfig = {
  allowSyntheticCcy: true,
  allowedSlippage: DEFAULT_SLIPPAGES[2],
  defaultSubAccountId: undefined as number | undefined,
  enableGroups: false,
  confirmOrderCancels: true,
  confirmOrderResume: true,
  allowSyntheticCrosses: false,
  rememberSubAccountId: true,
  useTradeAllocations: false,
  clickToTradeAll: false,
  clickToTradeDefaultSubaccount: undefined,
  enableCustomerPricing: false,
  customerPricingInitialExpanded: true,
  applyCustomerPricingDetailsToOrderComment: false,
  enableSalesWorkflow: false,
  linkCustomerHedgeSubaccount: false,
  enableDerivativeContractDefault: false,
  exclusivelyPrimePriceOnRePriming: false,
  enableCustomerBlotterColumns: false,
  selectedCustomerID: undefined as number | undefined,
  alwaysCheckPriceReasonability: true,
  enableCustomerTradeBookingOnCPC: false,
  defaultOrderStrategy: null as OrderStrategiesEnum | null,
  defaultOrderFormTab: null as unknown as OMSView,
  defaultPriceOffsets: '10|25',
  alwaysUseBBOForPriceOffsets: false,
  startTimeEntryMode: DateTimeDurationPickerValueType.DateTime,
  endTimeEntryMode: DateTimeDurationPickerValueType.DateTime,
  enableAdvancedOrderDetails: undefined as boolean | undefined, // when undefined default value will depend on user roles, check AppConfigProvider
  alwaysShowBestBidOffer: false,
  alwaysShowMarketCardBestBidOffer: false,
  showOrderFormRollupSelector: false,
  enableNewMarketSelector: false as boolean,
  bulkClosePositionQuoteCurrencies: ['USDT', 'USDC', 'USD'],
  enableReduceFirst: false,
  enableReduceOnly: false,
};

export const DefaultTradingSettingsMethods = {
  setAllowSyntheticCcy: (allowSyntheticCcy: boolean) => ({}),
  setAllowedSlippage: (allowedSlippage: Slippage) => ({}),
  setDefaultSubAccountId: (defaultSubAccountId: number | undefined) => ({}),
  setEnableGroups: (enableGroups: boolean) => ({}),
  setRememberSubAccountId: (rememberSubAccountId: boolean) => ({}),
  setUseTradeAllocations: (useTradeAllocations: boolean) => ({}),
  setClickToTradeAll: (clickToTradeAll: boolean) => ({}),
  setClickToTradeDefaultSubaccount: (defaultSubaccount: string) => ({}),
  setAllowSyntheticCrosses: (allowSyntheticCrosses: boolean) => ({}),
  setConfirmOrderCancels: (confirmOrderCancels: boolean) => ({}),
  setConfirmOrderResume: (confirmOrderResume: boolean) => ({}),
  setEnableCustomerPricing: (enableCustomerPricing: boolean) => ({}),
  setEnableSalesWorkflow: (enableSalesWorkflow: boolean) => ({}),
  setLinkCustomerHedgeSubaccount: (linkCustomerHedgeSubaccount: boolean) => ({}),
  setEnableDerivativeContractDefault: (enableDerivativeContractDefault: boolean) => ({}),
  setExclusivelyPrimePriceOnRePriming: (exclusivelyPrimePriceOnRePriming: boolean) => ({}),
  setEnableCustomerBlotterColumns: (enableCustomerBlotterColumns: boolean) => ({}),
  setCustomerPricingInitialExpanded: (customerPricingInitialExpanded: boolean) => ({}),
  setApplyCustomerPricingDetailsToOrderComment: (applyCustomerPricingDetailsToOrderComment: boolean) => ({}),
  setSelectedCustomerID: (selectedCustomerId: number | undefined) => ({}),
  setAlwaysCheckPriceReasonability: (alwaysCheckPriceReasonability: boolean) => ({}),
  setEnableCustomerTradeBookingOnCPC: (enableCustomerTradeBookingOnCPC: boolean) => ({}),
  setDefaultOrderStrategy: (orderStrategy: string) => ({}),
  setDefaultOrderFormTab: (tab: OMSView | '') => ({}),
  setDefaultPriceOffsets: (priceOffsets: string) => ({}),
  setAlwaysUseBBOForPriceOffsets: (alwaysUseBBOForPriceOffsets: boolean) => ({}),
  setStartTimeEntryMode: (startTimeEntryMode: DateTimeDurationPickerValueType) => ({}),
  setEndTimeEntryMode: (endTimeEntryMode: DateTimeDurationPickerValueType) => ({}),
  setEnableAdvancedOrderDetails: (enableAdvancedOrderDetails: boolean) => ({}),
  setAlwaysShowBestBidOffer: (alwaysShowBestBidOffer: boolean) => ({}),
  setAlwaysShowMarketCardBestBidOffer: (alwaysShowMarketCardBestBidOffer: boolean) => ({}),
  setShowOrderFormRollupSelector: (showOrderFormRollupSelector: boolean) => ({}),
  setEnableNewMarketSelector: (enableNewMarketSelector: boolean) => ({}),
  setBulkClosePositionQuoteCurrencies: (bulkClosePositionQuoteCurrencies: string[]) => ({}),
  setEnableReduceFirst: (enableReduceFirst: boolean) => ({}),
  setEnableReduceOnly: (enableReduceOnly: boolean) => ({}),
};
