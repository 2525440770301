import { uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { useTheme } from 'styled-components';

import {
  ACTION,
  Button,
  ButtonVariants,
  Checkbox,
  getDisplayNameOrNameLabel,
  getStringLabel,
  HelpIcon,
  HStack,
  isTalosUser,
  MixpanelEvent,
  MixpanelEventProperty,
  NotificationVariants,
  Panel,
  PanelContent,
  PanelHeader,
  SearchSelect,
  Text,
  useDynamicCallback,
  useGlobalToasts,
  useMixpanel,
  VStack,
} from '@talos/kyoko';

import { FormHeader, FormRow } from 'components/Form';

import { useFeatureFlag, useRoleAuth, useUser } from 'hooks';
import { useDisplaySettings, useTradingSettings } from 'providers/AppConfigProvider';
import { useSubAccounts } from '../../../providers';
import { BBOPriceOffsets } from '../BBOPriceOffsets';
import { DefaultOrderTab } from '../DefaultOrderTab';
import { DefaultPriceOffsets } from '../DefaultPriceOffsets';
import { DefaultStrategy } from '../DefaultStrategy';
import { BulkClosePositionQuoteCurrenciesSetting } from './BulkClosePositionQuoteCurrenciesSetting';
import { DefaultReduceFirst } from './DefaultReduceFirst/DefaultReduceFirst';
import { DefaultReduceOnly } from './DefaultReduceOnly/DefaultReduceOnly';
import { DisplayBBOCheckbox } from './DisplayBBOCheckbox';
import { ExclusivePriceOnRePrimingCheckbox } from './ExclusivelyPrimePriceOnRePrimingCheckbox';
import { MarketCardBBOCheckbox } from './MarketCardBBOCheckbox';
import { OrderDetailsCheckbox } from './OrderDetailsCheckbox';
import { SubAccountsSettings } from './SubAccountSettings';

// Generates all hour options with 30 minute selections
export const generateEODOptions = () => {
  const result: string[] = [];
  for (let i = 0; i < 24; i++) {
    const hours = i < 10 ? `0${i}` : i;
    ['00', '30'].forEach(minutes => {
      result.push(`${hours}:${minutes}`);
    });
  }
  return result;
};
const eodOptions = generateEODOptions();

export const Trading = () => {
  const theme = useTheme();
  const { homeCurrency, showTotalEstimates, setHomeCurrency, setShowTotalEstimates, customEOD, setCustomEOD } =
    useDisplaySettings();
  const {
    enableGroups,
    allowSyntheticCcy,
    clickToTradeAll,
    clickToTradeDefaultSubaccount,
    allowSyntheticCrosses,
    confirmOrderCancels,
    confirmOrderResume,
    setClickToTradeAll,
    setClickToTradeDefaultSubaccount,
    setEnableGroups,
    setAllowSyntheticCcy,
    setAllowSyntheticCrosses,
    setConfirmOrderCancels,
    setConfirmOrderResume,
    enableCustomerPricing,
    setEnableCustomerPricing,
    enableSalesWorkflow,
    setEnableSalesWorkflow,
    linkCustomerHedgeSubaccount,
    setLinkCustomerHedgeSubaccount,
    enableDerivativeContractDefault,
    setEnableDerivativeContractDefault,
    enableCustomerBlotterColumns,
    setEnableCustomerBlotterColumns,
    customerPricingInitialExpanded,
    setCustomerPricingInitialExpanded,
    applyCustomerPricingDetailsToOrderComment,
    setEnableCustomerTradeBookingOnCPC,
    enableCustomerTradeBookingOnCPC,
    setApplyCustomerPricingDetailsToOrderComment,
    alwaysCheckPriceReasonability,
    setAlwaysCheckPriceReasonability,
    enableNewMarketSelector,
    setEnableNewMarketSelector,
  } = useTradingSettings();
  const { homeCurrencyAdditionalCurrencies, enableBulkClosePosition } = useFeatureFlag();
  const { add } = useGlobalToasts();
  const { isAuthorized } = useRoleAuth();
  const user = useUser();
  const { tradableSubAccounts } = useSubAccounts();
  const [formHomeCurrency, setFormHomeCurrency] = useState(homeCurrency);
  const mixpanel = useMixpanel();

  const selectedClickToTradeDefaultSubaccount = useMemo(
    () => tradableSubAccounts?.find(s => s.Name === clickToTradeDefaultSubaccount),
    [tradableSubAccounts, clickToTradeDefaultSubaccount]
  );

  const saveHomeCurrency = e => {
    e.preventDefault();
    setHomeCurrency(formHomeCurrency);
    add({
      text: `Home currency changed.`,
      variant: NotificationVariants.Positive,
    });
  };

  const handleEODChange = useDynamicCallback((newEOD: string | undefined) => {
    if (newEOD == null) {
      return;
    }

    const values = newEOD.split(':');
    const [hours, minutes] = [values[0], values[1]];
    setCustomEOD({ hours, minutes });
    add({
      text: `End of day shortcut changed.`,
      variant: NotificationVariants.Positive,
    });
  });

  const handleHomeCurrencyChange = useDynamicCallback((newHomeCurrency: string | undefined) => {
    if (newHomeCurrency) {
      mixpanel.track(MixpanelEvent.ChangeHomeCurrency, {
        [MixpanelEventProperty.Currency]: newHomeCurrency,
      });
      setFormHomeCurrency(newHomeCurrency);
    }
  });

  const homeCurrencyOptions = useMemo(() => {
    return uniq(['USD', 'EUR', 'CAD', 'BTC', 'CHF'].concat(homeCurrencyAdditionalCurrencies));
  }, [homeCurrencyAdditionalCurrencies]);

  return (
    <Panel>
      <PanelHeader>
        <h2>Trading</h2>
      </PanelHeader>
      <PanelContent>
        <form>
          <FormHeader>Click to trade</FormHeader>
          <FormRow>
            <Checkbox
              id="exchange"
              name="exchange"
              checked={clickToTradeAll}
              onChange={e => {
                mixpanel.track(MixpanelEvent.EnableClickToTrade, {
                  [MixpanelEventProperty.Enabled]: e.target.checked,
                });
                setClickToTradeAll(e.target.checked);
                add({
                  text: `Click to trade ${e.target.checked ? 'enabled' : 'disabled'}.`,
                  variant: NotificationVariants.Positive,
                });
              }}
            >
              Enable click to trade
            </Checkbox>
          </FormRow>
          <div style={{ marginLeft: theme.spacingLarge }}>
            If enabled, double left clicking on the market card will place an aggressive crossing order. Double right
            clicking will place a passive order. In both cases these orders will by default be placed with a time in
            force of GTC. Contact Talos support if you would like the aggressive orders to instead be placed as FOK.
          </div>

          {tradableSubAccounts && tradableSubAccounts.length > 0 && (
            <VStack
              gap="spacingDefault"
              alignItems="flex-start"
              mt="spacingDefault"
              pl="spacingLarge"
              opacity={clickToTradeAll ? undefined : 0.4}
            >
              <Text color="colorTextImportant">Default Click to Trade Sub Account</Text>
              <span>
                Specify a default sub account to be used when booking orders generated via Click to Trade. This will be
                used if no default sub account has been specified on the market tab.
              </span>
              <SearchSelect
                disabled={!clickToTradeAll}
                selection={selectedClickToTradeDefaultSubaccount}
                options={tradableSubAccounts}
                getLabel={getDisplayNameOrNameLabel}
                onChange={defaultSubaccount => setClickToTradeDefaultSubaccount(defaultSubaccount?.Name ?? '')}
                showClear
                style={{ width: '200px' }}
              />
            </VStack>
          )}
        </form>

        {isTalosUser(user) && (
          <>
            <FormHeader>Market Selector</FormHeader>
            <FormRow>
              <Checkbox
                id="use-new-market-selector"
                name="use-new-market-selector"
                checked={enableNewMarketSelector}
                onChange={e => {
                  setEnableNewMarketSelector(e.target.checked);
                }}
              >
                Use new Market Selector (in development - Talos users only)
              </Checkbox>
            </FormRow>
          </>
        )}

        <FormHeader>Orders</FormHeader>
        <FormRow>
          <Checkbox
            checked={enableDerivativeContractDefault}
            onChange={e => {
              mixpanel.track(MixpanelEvent.EnableDefaultingDerivativeQuantityToContracts, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setEnableDerivativeContractDefault(e.target.checked);
            }}
          >
            Default derivative order entry quantity to contracts{' '}
            <HelpIcon tooltip="If enabled, when entering an order for a contract traded derivative, the Qty will default to contract terms rather than base/quote currency terms." />
          </Checkbox>
        </FormRow>
        <FormRow>
          <Checkbox
            id="groups"
            name="groups"
            checked={enableGroups}
            onChange={e => {
              mixpanel.track(MixpanelEvent.OrderGroups, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setEnableGroups(e.target.checked);
              add({
                text: `Order groups ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Enable order groups
          </Checkbox>
        </FormRow>

        <div style={{ margin: `0px 0px ${theme.spacingMedium}px ${theme.spacingLarge}px` }}>
          Use order groups to associate related orders.
        </div>
        <FormRow>
          <Checkbox
            id="confirmCxls"
            name="confirmCxls"
            checked={confirmOrderCancels}
            onChange={e => {
              mixpanel.track(MixpanelEvent.EnableConfirmOrderCancels, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setConfirmOrderCancels(e.target.checked);
              add({
                text: `Confirm order cancels ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Confirm order cancels
          </Checkbox>
        </FormRow>
        <FormRow>
          <Checkbox
            id="confirmResume"
            name="confirmResume"
            checked={confirmOrderResume}
            onChange={e => {
              mixpanel.track(MixpanelEvent.EnableConfirmOrderResume, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setConfirmOrderResume(e.target.checked);
              add({
                text: `Confirm order resume ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Confirm order resume
          </Checkbox>
        </FormRow>
        <FormRow>
          <Checkbox
            id="alwaysCheckPriceReasonability"
            name="alwaysCheckPriceReasonability"
            checked={alwaysCheckPriceReasonability}
            onChange={e => {
              mixpanel.track(MixpanelEvent.EnableCheckLimitPriceReasonability, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setAlwaysCheckPriceReasonability(e.target.checked);
              add({
                text: `Always check limit price reasonability ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Always check limit price reasonability{' '}
            <HelpIcon tooltip="By default, the limit price reasonability check does not run for Pegged, TWAP, Steady Pace or Time Sliced orders. The check will never run on basis orders" />
          </Checkbox>
        </FormRow>

        <FormRow>
          <DefaultReduceOnly />
        </FormRow>
        <FormRow>
          <DefaultReduceFirst />
        </FormRow>

        <FormRow>
          <DefaultStrategy />
        </FormRow>
        <FormRow>
          <DefaultOrderTab />
        </FormRow>
        <FormRow>
          <DefaultPriceOffsets />
        </FormRow>
        <FormRow>
          <BBOPriceOffsets />
        </FormRow>
        <FormRow>
          <OrderDetailsCheckbox />
        </FormRow>
        <FormRow>
          <DisplayBBOCheckbox />
        </FormRow>
        <FormRow>
          <MarketCardBBOCheckbox />
        </FormRow>
        <FormRow>
          <ExclusivePriceOnRePrimingCheckbox />
        </FormRow>

        <FormHeader>RFQs</FormHeader>
        <FormRow>
          <Checkbox
            checked={allowSyntheticCcy}
            onChange={e => {
              mixpanel.track(MixpanelEvent.AllowSyntheticCounterCurrencyRequests, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setAllowSyntheticCcy(e.target.checked);
              add({
                text: `Synthetic counter currency requests ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Allow synthetic counter currency requests
          </Checkbox>
        </FormRow>
        <FormRow>
          <Checkbox
            checked={allowSyntheticCrosses}
            onChange={e => {
              mixpanel.track(MixpanelEvent.AllowSyntheticCrosses, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setAllowSyntheticCrosses(e.target.checked);
              add({
                text: `Synthetic crosses ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Allow synthetic crosses
          </Checkbox>
        </FormRow>

        <SubAccountsSettings />

        <FormHeader>Home Currency</FormHeader>
        <form onSubmit={e => saveHomeCurrency(e)}>
          <FormRow>
            <HStack gap="spacingDefault">
              <SearchSelect
                selection={formHomeCurrency}
                options={homeCurrencyOptions}
                onChange={handleHomeCurrencyChange}
                getLabel={getStringLabel}
                showDropdownSearch={false}
                style={{ width: '200px' }}
              />

              <Button variant={ButtonVariants.Positive} type="submit" disabled={formHomeCurrency === homeCurrency}>
                Save
              </Button>
            </HStack>
          </FormRow>
        </form>

        <FormHeader>Total estimates</FormHeader>
        <FormRow>
          <Checkbox
            id="showTotalEstimates"
            name="showTotalEstimates"
            checked={showTotalEstimates}
            onChange={e => {
              mixpanel.track(MixpanelEvent.EnableTotalCostOnHover, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setShowTotalEstimates(e.target.checked);
              add({
                text: `Total estimates ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Enable total cost on hover
          </Checkbox>
        </FormRow>
        <div style={{ marginLeft: theme.spacingLarge }}>
          If enabled, the estimated total value of the level is shown on hover on cards.
        </div>
        <FormHeader>End of Day (EOD)</FormHeader>
        <FormRow>
          <SearchSelect
            selection={`${customEOD.hours}:${customEOD.minutes}`}
            options={eodOptions}
            getLabel={getStringLabel}
            onChange={handleEODChange}
            style={{ width: '200px' }}
          />
        </FormRow>
        <FormHeader>Customer Pricing</FormHeader>
        <FormRow>
          <Checkbox
            checked={enableCustomerPricing}
            onChange={e => {
              mixpanel.track(MixpanelEvent.CustomerPricingCalculatorInOrderForm, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setEnableCustomerPricing(e.target.checked);
              add({
                text: `Customer Pricing Calculator ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Enable Customer Pricing Calculator in the Order Form
          </Checkbox>
        </FormRow>
        <FormRow style={{ paddingLeft: theme.spacingMedium }}>
          <Checkbox
            checked={enableCustomerPricing && customerPricingInitialExpanded}
            disabled={!enableCustomerPricing}
            onChange={e => {
              mixpanel.track(MixpanelEvent.CustomerPricingCalculatorStartsExpanded, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setCustomerPricingInitialExpanded(e.target.checked);
              add({
                text: `Customer Pricing settings saved.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Customer Pricing Calculator starts expanded
          </Checkbox>
        </FormRow>
        <FormRow style={{ paddingLeft: theme.spacingMedium }}>
          <Checkbox
            checked={enableCustomerPricing && applyCustomerPricingDetailsToOrderComment}
            disabled={!enableCustomerPricing}
            onChange={e => {
              mixpanel.track(MixpanelEvent.ApplyDetailsToOrderComment, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setApplyCustomerPricingDetailsToOrderComment(e.target.checked);
              add({
                text: `Customer Pricing settings saved.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Apply details to Order comment{' '}
            <HelpIcon tooltip="When submitting an order which has been primed using the Customer Pricing Calculator, appends details from the Customer Pricing Calculator to the order comment." />
          </Checkbox>
        </FormRow>
        <FormRow style={{ paddingLeft: theme.spacingMedium }}>
          <Checkbox
            checked={enableCustomerPricing && enableCustomerTradeBookingOnCPC}
            disabled={!enableCustomerPricing}
            onChange={e => {
              mixpanel.track(MixpanelEvent.OptionToBookCustomerTrade, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setEnableCustomerTradeBookingOnCPC(e.target.checked);
              add({
                text: `Customer Pricing settings saved.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Enable option to Book Customer Trade
          </Checkbox>
        </FormRow>
        <FormRow style={{ paddingLeft: theme.spacingMedium * 2 }}>
          <Checkbox
            checked={enableCustomerPricing && enableCustomerTradeBookingOnCPC && linkCustomerHedgeSubaccount}
            disabled={!enableCustomerTradeBookingOnCPC || !enableCustomerPricing}
            onChange={e => {
              mixpanel.track(MixpanelEvent.LinkCustomerHedgeSubaccount, {
                [MixpanelEventProperty.Enabled]: e.target.checked,
              });
              setLinkCustomerHedgeSubaccount(e.target.checked);
              add({
                text: `Link customer hedge subaccount ${e.target.checked ? 'enabled' : 'disabled'}.`,
                variant: NotificationVariants.Positive,
              });
            }}
          >
            Link Customer Hedge Subaccount
          </Checkbox>
        </FormRow>
        {isAuthorized(ACTION.VIEW_DEALER) && (
          <>
            <FormHeader>Sales Workflow</FormHeader>
            <FormRow>
              <Checkbox
                checked={enableSalesWorkflow}
                onChange={e => {
                  mixpanel.track(MixpanelEvent.SalesWorkflow, {
                    [MixpanelEventProperty.Enabled]: e.target.checked,
                  });
                  setEnableSalesWorkflow(e.target.checked);
                  add({
                    text: `Sales Workflow ${e.target.checked ? 'enabled' : 'disabled'}.`,
                    variant: NotificationVariants.Positive,
                  });
                }}
              >
                Enable Sales Workflow
              </Checkbox>
            </FormRow>
            <FormRow>
              <Checkbox
                checked={enableCustomerBlotterColumns}
                onChange={e => {
                  mixpanel.track(MixpanelEvent.CustomerBlotterColumns, {
                    [MixpanelEventProperty.Enabled]: e.target.checked,
                  });
                  setEnableCustomerBlotterColumns(e.target.checked);
                  add({
                    text: `Customer blotter columns ${e.target.checked ? 'enabled' : 'disabled'}.`,
                    variant: NotificationVariants.Positive,
                  });
                }}
              >
                Enable Customer Blotter Columns
              </Checkbox>
            </FormRow>
          </>
        )}

        {enableBulkClosePosition && <BulkClosePositionQuoteCurrenciesSetting />}
      </PanelContent>
    </Panel>
  );
};
